import { faLaptopCode, faMapMarkerAlt, faPhoneAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Image, Modal, Row } from 'react-bootstrap'; // Import Modal
import { useParams } from 'react-router-dom';

const ReportDetails = () => {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false); // State for showing image modal

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(`https://hiredsafe.com/PHP/get_report_by_id.php?id=${id}`);
        console.log('Fetched report:', response.data);

        if (response.data.status === 'success') {
          setReport(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError('An error occurred while fetching the report');
        console.error('Error fetching report:', error);
      }
    };

    fetchReport();
  }, [id]);

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
  };

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
        <div className="text-center">
          <Button href="/" variant="primary">
            Back to Reports
          </Button>
        </div>
      </Container>
    );
  }

  if (!report) {
    return (
      <Container className="py-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Container>
    );
  }

  return (
    <>
      <style>{`
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f4f4f4;
        }
        .content {
          padding: 20px;
          background-color: #fff;
          max-width: 1200px;
          margin: 20px auto;
          border-radius: 10px;
        }
        .company-info {
          margin-bottom: 30px;
        }
        .fraud-history {
          background-color: #78909c;
          color: #fff;
          padding: 20px;
          border-radius: 10px;
          margin-bottom: 20px;
        }
        .fraud-history h2 {
          font-size: 22px;
          margin-bottom: 20px;
        }
        .fraud-history ul {
          list-style: none;
          padding: 0;
        }
        .fraud-history li {
          margin-bottom: 15px;
          font-size: 16px;
          white-space: pre-wrap;
          max-height: 300px;
          overflow: auto;
        }

        /* Hide scrollbar but allow scrolling */
        .fraud-history li::-webkit-scrollbar {
          display: none;
        }

        .fraud-history li {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }

        .company-details {
          text-align: left;
        }
        .company-img {
          width: 100%;
          border-radius: 10px;
          margin-bottom: 20px;
          cursor: pointer; /* Add pointer cursor to indicate clickability */
        }
        .company-details h3 {
          font-size: 22px;
          margin-bottom: 20px;
          color: #333;
        }
        .company-details p {
          margin: 15px 0;
          font-size: 16px;
          color: #333;
        }
        .company-details i {
          margin-right: 10px;
          color: #3e7d92;
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .fraud-history, .company-details {
            margin-bottom: 20px;
          }
          .fraud-history h2, .company-details h3 {
            font-size: 20px;
          }
          .fraud-history li, .company-details p {
            font-size: 14px;
          }
        }

        @media (max-width: 576px) {
          .fraud-history h2, .company-details h3 {
            font-size: 18px;
          }
          .fraud-history li, .company-details p {
            font-size: 12px;
          }
        }
      `}</style>

      <Container className="content mt-5">
        <Row>
          <Col md={12} lg={7}>
            <div className="company-info">
              <h1><b>{report.COMPANY_NAME}</b></h1>
              <div className="fraud-history">
                <h2>Fraud History</h2>
                <ul>
                  <span>Reported on {new Date(report.DATEOFSCAM).toLocaleDateString()}</span>
                  {report.DESCRIPTION_OF_SCAM ? (
                    <li>
                      {report.DESCRIPTION_OF_SCAM}
                    </li>
                  ) : (
                    <li>No fraud history reported.</li>
                  )}
                </ul>
              </div>
            </div>
          </Col>

          {/* Spacer column to control spacing on larger devices */}
          <Col md={1}></Col>

          <Col md={12} lg={4}>
            <div className="company-details">
              {report.EVIDENCE && (
                <Image 
                  variant="top" 
                  src={report.EVIDENCE} 
                  alt="Evidence" 
                  fluid
                  className="company-img"
                  onClick={handleImageClick} // Open modal on image click
                />
              )}

              <h3>Company Details</h3>
              <Row>
                <Col xs={12} md={6}>
                  <p><FontAwesomeIcon icon={faLaptopCode} /> Industry: <b>{report.INDUSTRY}</b></p>
                  <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Location: <b>{report.COMPANY_ADRESS}</b></p>
                </Col>
                <Col xs={12} md={6}>
                  <p><FontAwesomeIcon icon={faUsers} /> Reported by: <b>{report.REPORTER_NAME}</b></p>
                  <p><FontAwesomeIcon icon={faPhoneAlt} /> Contact: <b>{report.industry_contact}</b></p>
                </Col>
              </Row>
              <Button href="/" variant="primary" className="mt-3">
                Back to Reports
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for displaying full-size image */}
      <Modal show={showImageModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Body>
          {report.EVIDENCE && (
            <Image 
              src={report.EVIDENCE} 
              alt="Evidence" 
              fluid
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportDetails;
