import axios from 'axios';
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';

const AdminBlogPage = () => {
  const [formData, setFormData] = useState({
    name: 'HiredSafe', // Default name
    email: '',
    companyName: '',
    title: '',
    description: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      const response = await axios.post('https://hiredsafe.com/PHP/submit_blog.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.status === 'success') {
        alert('Blog submitted successfully');
        // Clear the form after successful submission, keeping the default "Admin" name
        setFormData({
          name: 'HiredSafe', // Default name remains
          email: '',
          companyName: '',
          title: '',
          description: '',
          file: null,
        });
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('There was an error submitting the blog:', error);
      alert('There was an error submitting the blog.');
    }
  };

  return (
    <>
      <main>
        <section className="blog-form-section">
          <Container>
            <h2>Write a Blog (Hiredsafe)</h2>
            <Form className="blog-form" onSubmit={handleSubmit} encType="multipart/form-data">
              <Form.Group controlId="your-name">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  readOnly // Make the name field read-only
                />
              </Form.Group>
              <Form.Group controlId="your-email">
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="company-name">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the company name involved in report (if have any)"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="blog-title">
                <Form.Label>Title of The Blog</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the title of the blog"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="blog-description">
                <Form.Label>Description of Blog</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Provide a detailed description of the blog"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="upload">
                <Form.Label>Upload</Form.Label>
                <Form.Control type="file" name="file" onChange={handleFileChange} />
              </Form.Group>
              <Button variant="primary" type="submit" className="submit-btn">
                Submit Blog
              </Button>
            </Form>
          </Container>
        </section>
      </main>
    </>
  );
};

export default AdminBlogPage;
