import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import about from "../assets/about.jpg"; // Import the image

const About = () => {
  return (
    <div>
      {/* About Hero Section */}
      <section className="about-hero py-4" style={{ backgroundColor: '#287094', color: '#ffffff', textAlign: 'center' }}>
        <Container>
          <h2 style={{ fontSize: '32px', fontWeight: 'bold', lineHeight: '1.2' }}>
            Our Shield in the Job Market:<br />
            Ensuring Trust and Safety<br />
            for Every Job Seeker
          </h2>
        </Container>
      </section>

      {/* About Content Section */}
      <section className="about-content py-5">
        <Container>
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>

          {/* Four Points Above the Image */}
          <Row className="mt-4">
            <Col md={5}> <h3 style={{ fontSize: '24px', color: '#287094', fontWeight: 'bold', marginBottom: '20px' }}>About HiredSafe</h3>
              <p style={{ fontSize: '16px', color: '#555', marginBottom: '20px' }}>
                At HiredSafe, we are dedicated to creating a secure and transparent job market. Our mission is to empower job seekers with the knowledge and tools they need to avoid scams and find legitimate opportunities.
              </p>
             </Col>
            <Col md={1}></Col>
            <Col md={3}>
              <h4 style={{ fontSize: '18px', color: '#287094', fontWeight: 'bold', marginBottom: '10px' }}>Trusted Employers</h4>
              <p style={{ fontSize: '14px', color: '#555' }}>We partner with reputable employers to ensure job listings are legitimate and safe.</p>
              <h4 style={{ fontSize: '18px', color: '#287094', fontWeight: 'bold', marginBottom: '10px' }}>Empowered Job Seekers</h4>
              <p style={{ fontSize: '14px', color: '#555' }}>We provide resources and guidance to help you navigate the job market with confidence.</p>
            </Col>
            <Col md={3}>
              <h4 style={{ fontSize: '18px', color: '#287094', fontWeight: 'bold', marginBottom: '10px' }}>Verified Candidates</h4>
              <p style={{ fontSize: '14px', color: '#555' }}>Our thorough vetting process helps job seekers build trust and credibility.</p>
              <h4 style={{ fontSize: '18px', color: '#287094', fontWeight: 'bold', marginBottom: '10px' }}>Secure Platform</h4>
              <p style={{ fontSize: '14px', color: '#555' }}>Our platform is designed with robust security measures to protect your data.</p>
            </Col>
          </Row>

          {/* About Section with Gap */}
          <Row className="mt-2">
            <Col md={6}>
              <p style={{ fontSize: '24px', color: '#555', marginBottom: '20px', }}>At HiredSafe, we offer:</p>
              <ul style={{ paddingLeft: '20px', listStyleType: 'disc', color: '#555' }}>
                <li style={{ marginBottom: '10px' }}>Verified Company Profiles: Gain insights into potential employers with our comprehensive and trusted database.</li>
                <li style={{ marginBottom: '10px' }}>Up-to-Date Scam Alerts: Stay informed about the latest job scams and how to avoid them.</li>
                <li style={{ marginBottom: '10px' }}>Expert Resources: Access valuable articles, guides, and tips to enhance your job search and protect your personal information.</li>
              </ul>
            </Col>
            <Col md={6}>
              <img src={about} alt="About HiredSafe" style={{ maxWidth: '100% ', height: 'auto', borderRadius: '2px', marginTop: '20px' }} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
