import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';

const ReportControl = ({ handleVerify, handlePublish, handleDelete }) => {
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportToDelete, setReportToDelete] = useState(null); // State to store the report to be deleted

  // Fetch all reports from the server
  const fetchReports = async () => {
    try {
      const response = await axios.get('https://hiredsafe.com/PHP/get_all_reports.php');
      console.log('Fetched reports:', response.data); // Log the fetched data to debug
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  // Handle the "View" button click to set the selected report and show the modal
  const handleViewReport = (report) => {
    setSelectedReport(report); // Set the selected report
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedReport(null); // Clear the selected report when the modal closes
  };

  // Show delete confirmation modal
  const handleDeleteReport = (reportId) => {
    setReportToDelete(reportId); // Set the report to be deleted
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  // Confirm delete and call the delete function
  const confirmDelete = () => {
    if (reportToDelete) {
      handleDelete(reportToDelete); // Call the actual delete function passed as a prop
      setReports((prevReports) =>
        prevReports.filter((report) => report.id !== reportToDelete) // Remove deleted report from the state
      );
      setReportToDelete(null); // Clear the state
      setShowDeleteModal(false); // Close the modal
    }
  };

  const handleCloseDeleteModal = () => {
    setReportToDelete(null); // Clear the report to delete
    setShowDeleteModal(false); // Hide the delete confirmation modal
  };

  const counts = useMemo(() => {
    const pending = reports.filter(report => report.status === 'Pending').length;
    const verified = reports.filter(report => report.status === 'Verified').length;
    const published = reports.filter(report => report.status === 'Published').length;
    return { pending, verified, published };
  }, [reports]);

  // Update the status of a report locally after verify
  const handleVerifyReport = async (reportId) => {
    try {
      await handleVerify(reportId);  // Perform the verify action (assumed to be passed as a prop)
      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId ? { ...report, status: 'Verified' } : report
        )
      );
    } catch (error) {
      console.error('Error verifying report:', error);
    }
  };

  // Update the status of a report locally after publish
  const handlePublishReport = async (reportId) => {
    try {
      await handlePublish(reportId);  // Perform the publish action (assumed to be passed as a prop)
      setReports((prevReports) =>
        prevReports.map((report) =>
          report.id === reportId ? { ...report, status: 'Published' } : report
        )
      );
    } catch (error) {
      console.error('Error publishing report:', error);
    }
  };

  return (
    <div>
      <h2>Report Control</h2>

      <Row className="mb-4">
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Pending Reports</h4>
            <p style={summaryCountStyle}>{counts.pending}</p>
          </div>
        </Col>
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Verified Reports</h4>
            <p style={summaryCountStyle}>{counts.verified}</p>
          </div>
        </Col>
        <Col>
          <div style={summaryBoxStyle}>
            <h4 style={summaryHeadingStyle}>Published Reports</h4>
            <p style={summaryCountStyle}>{counts.published}</p>
          </div>
        </Col>
      </Row>

      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ backgroundColor: '#287094', color: '#ffffff' }}>
            <th>ID</th>
            <th>Company</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map(report => (
            <tr key={report.id}>
              <td>{report.id}</td>
              <td>{report.COMPANY_NAME || 'No Company Name'}</td>
              <td>{report.DATEOFSCAM ? new Date(report.DATEOFSCAM).toLocaleDateString() : 'No Date'}</td>
              <td>
                <span className="status" style={statusStyle(report.status)}>
                  {report.status || 'No Status'}
                </span>
              </td>
              <td>
                <div className="d-flex gap-2">
                  {report.status !== 'Verified' && (
                    <Button variant="success" onClick={() => handleVerifyReport(report.id)}>
                      Verify
                    </Button>
                  )}
                  {report.status === 'Verified' && (
                    <Button variant="primary" onClick={() => handlePublishReport(report.id)}>
                      Publish
                    </Button>
                  )}
                  <Button variant="info" onClick={() => handleViewReport(report)}>
                    View
                  </Button>
                  <Button variant="danger" onClick={() => handleDeleteReport(report.id)}>
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for viewing report details */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Report Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedReport ? (
            <div>
              <p><strong>ID:</strong> {selectedReport.id}</p>
              <p><strong>Company Name:</strong> {selectedReport.COMPANY_NAME}</p>
              <p><strong>Company Address:</strong> {selectedReport.COMPANY_ADRESS}</p>
              <p><strong>Industry:</strong> {selectedReport.INDUSTRY}</p>
              <p><strong>Date of Scam:</strong> {selectedReport.DATEOFSCAM ? new Date(selectedReport.DATEOFSCAM).toLocaleDateString() : 'No Date'}</p>
              <p><strong>Description of Scam:</strong> {selectedReport.DESCRIPTION_OF_SCAM}</p>
              <p><strong>Reporter Name:</strong> {selectedReport.REPORTER_NAME}</p>
              <p><strong>Reporter Email:</strong> {selectedReport.REPORTER_EMAIL}</p>
              {selectedReport.EVIDENCE && (
                <div>
                  <strong>Evidence:</strong>
                  <img
                    src={selectedReport.EVIDENCE}
                    alt="evidence"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
              )}
            </div>
          ) : (
            <p>No report selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this report?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// Styles for the status badge
const statusStyle = (status) => ({
  padding: '5px 10px',
  borderRadius: '4px',
  fontSize: '14px',
  backgroundColor: status === 'Pending' ? '#ffcc00' : status === 'Verified' ? '#2196f3' : '#4caf50',
  color: '#fff',
});

// Styles for the summary boxes
const summaryBoxStyle = {
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

const summaryHeadingStyle = {
  fontSize: '18px',
  color: '#287094',
  marginBottom: '10px',
};

const summaryCountStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
};

export default ReportControl;
