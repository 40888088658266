import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';  // Import Link for routing

const SearchResults = ({ results }) => {
  console.log('Received search results in component:', results); // Log the exact structure of the data

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return 'No description available';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <Container className="my-4">
      <h3>Search Results</h3>
      {results.length > 0 ? (
        <Row>
          {results.map((result) => (
            <Col md={4} className="mb-4" key={result.id || result.COMPANY_NAME || result.blog_id}>
              <Card>
                <Card.Body>
                  {/* Display title based on whether it's a report or a blog */}
                  <Card.Title>{result.COMPANY_NAME || result.title || 'No title available'}</Card.Title>
                  
                  {/* Display truncated description or content */}
                  <Card.Text>
                    {truncateText(result.DESCRIPTION_OF_SCAM || result.blog_description || result.content, 150)}
                  </Card.Text>

                  {/* Conditionally render the link based on the result type */}
                  {result.type === 'report' ? (
                    <Link to={`/reportdetails/${result.id}`} className="btn btn-primary">
                      View Report Details
                    </Link>
                  ) : (
                    <Link to={`/blog-details/${result.id}`} className="btn btn-primary">
                      View Blog Details
                    </Link>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>No results found.</p>
      )}
    </Container>
  );
};

export default SearchResults;
