import { faBell, faBlog, faCog, faFileAlt, faLifeRing, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'; // Add faSignOutAlt for logout icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Button, Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from "../../assets/Navbarlogo.jpg";
import AdminSupport from './AdminSupport';
import BlogControl from './BlogControl';
import ReportControl from './ReportControl';

const AdminDashboard = () => {
  const [reports, setReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [activeComponent, setActiveComponent] = useState('reports');
  const navigate = useNavigate(); // Initialize useNavigate for redirecting

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get('https://hiredsafe.com/PHP/get_report_by_id.php');
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
  }, []);

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('jwtToken');
    // Redirect to the login page
    navigate('/adminlogin');
  };

  const handleVerify = async (id) => {
    try {
      await axios.post(`https://hiredsafe.com/PHP/verify_report.php`, { id: id });
      setReports(reports.map(report => report.id === id ? { ...report, status: 'Verified' } : report));
    } catch (error) {
      console.error('Error verifying report:', error);
    }
  };
  
  const handlePublish = async (id) => {
    try {
      const response = await axios.post(`https://hiredsafe.com/PHP/publish_report.php`, { id: id });
      if (response.status === 200) {
        setReports(reports.map(report => report.id === id ? { ...report, status: 'Published' } : report));
      }
    } catch (error) {
      console.error('Error publishing report:', error);
    }
  };
  
  const handleDelete = async (id) => {
    try {
      const response = await axios.post(
        `https://hiredsafe.com/PHP/delete_report.php`,
        { id: id },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 200) {
        setReports(reports.filter(report => report.id !== id));
      }
    } catch (error) {
      console.error('Error deleting report:', error.response ? error.response.data : error.message);
    }
  };
  
  const handleView = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReport(null);
  };

  return (
    <div style={styles.dashboard}>
      {/* Sidebar */}
      <div style={styles.sidebar}>
        <Image src={logo} width={'200px'} />
        <div style={styles.logo}>Admin</div>
        <Button 
          variant={activeComponent === 'reports' ? "primary" : "outline-light"} 
          className="mb-3 w-100 ml-5" 
          onClick={() => setActiveComponent('reports')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Report Control
        </Button>
        <Button 
          variant={activeComponent === 'blogs' ? "primary" : "outline-light"} 
          className="mb-3 w-100" 
          onClick={() => setActiveComponent('blogs')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faBlog} className="mr-2" /> Blog Control
        </Button>
        <Button 
          variant={activeComponent === 'support' ? "primary" : "outline-light"} 
          className="w-100" 
          onClick={() => setActiveComponent('support')}
          style={styles.sidebarButton}
        >
          <FontAwesomeIcon icon={faLifeRing} className="mr-2" /> Support
        </Button>
      </div>

      {/* Main Content */}
      <div style={styles.content}>
        {/* Top Navbar */}
        <Navbar bg="light" expand="lg" className="mb-4" style={styles.navbar}>
          <Nav className="ml-auto">
            <Nav.Link href="/adminblog">
              <Button variant="success" style={styles.writeBlogButton}>
                Write Blog
              </Button>
            </Nav.Link>
            <Nav.Link href="#profile">
              <FontAwesomeIcon icon={faUserCircle} size="lg" />
            </Nav.Link>
            <Nav.Link href="#notifications">
              <FontAwesomeIcon icon={faBell} size="lg" />
            </Nav.Link>
            <Nav.Link href="#settings">
              <FontAwesomeIcon icon={faCog} size="lg" />
            </Nav.Link>
            {/* Logout Button */}
            <Nav.Link onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} size="lg" /> Logout
            </Nav.Link>
          </Nav>
        </Navbar>
        <Container fluid>
          {activeComponent === 'reports' && (
            <ReportControl 
              reports={reports} 
              handleVerify={handleVerify} 
              handlePublish={handlePublish} 
              handleDelete={handleDelete} 
              handleView={handleView} 
              showModal={showModal} 
              selectedReport={selectedReport} 
              handleCloseModal={handleCloseModal}
            />
          )}
          {activeComponent === 'blogs' && <BlogControl />}
          {activeComponent === 'support' && <AdminSupport />}
        </Container>
      </div>
    </div>
  );
};

export default AdminDashboard;

const styles = {
  dashboard: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#f1f2f6',
  },
  sidebar: {
    width: '250px',
    height: '100vh',
    backgroundColor: '#2d3436',
    color: '#dfe6e9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.15)',
    position: 'fixed', // Fix the sidebar in place
    top: '0',
    left: '0',
    zIndex: '1000', // Ensure it stays above content
  },
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '30px',
  },
  sidebarButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  content: {
    flexGrow: 1,
    padding: '80px 20px 20px 20px', // Leave space for the fixed navbar
    marginLeft: '250px', // Adjust for fixed sidebar
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #dfe6e9',
    overflowY: 'auto',
    height: '100vh', // Ensure content is scrollable
  },
  writeBlogButton: {
    fontSize: '14px',
    padding: '8px 16px',
    borderRadius: '5px',
  },
  navbar: {
    position: 'fixed', // Fix the top navbar in place
    top: '0',
    left: '250px', // Adjust for the fixed sidebar width
    right: '0',
    zIndex: '1000', // Ensure it stays on top
    backgroundColor: '#f8f9fa',
    padding: '10px 20px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)', // Optional: Add shadow for separation
  },
};
