import React, { useState } from 'react';
import { Navbar, Form, FormControl, Button, Container,Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/hiredsafelogo.png';

const Navbar1 = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search input value
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the form from submitting the traditional way
    onSearch(searchQuery); // Call onSearch function passed from parent with searchQuery
  };

  return (
    <Navbar bg="light" expand="lg" className="border-bottom shadow-sm py-2 sticky-top">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="Logo"
            style={{ height: '50px' }} // Adjust the height as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Form inline className="mr-auto flex-grow-1 d-flex" onSubmit={handleSearchSubmit}>
  <FormControl
    type="text"
    placeholder="Search Companies or Blogs"
    className="w-50"
    value={searchQuery}
    onChange={handleSearchChange}
  />
  {/* Add custom margin with inline style */}
  <Button type="submit" variant="outline-success" style={{ marginLeft: '10px' }}>Search</Button>
</Form>

          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/reports">Reports</Nav.Link>
            <Nav.Link href="/blog-details">Blogs</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>

            <Nav.Link href="/contact">Contact</Nav.Link>
            <Button href="/reportscam" className="btn btn-primary">Report Scam</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbar1;
