// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Helper function to check if user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem('jwtToken');
  
  // Check if token exists
  if (!token) {
    return false;
  }

  // Optionally: Add token validation logic here (e.g., check expiry date)
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT token
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    if (decodedToken.exp < currentTime) {
      return false; // Token is expired
    }

    return true; // Token is valid and not expired
  } catch (error) {
    console.error('Token parsing error:', error);
    return false;
  }
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // If not authenticated, redirect to login page
    return <Navigate to="/adminlogin" />;
  }

  // If authenticated, render the child component (e.g., AdminDashboard)
  return children;
};

export default ProtectedRoute;
